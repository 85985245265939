// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chronik-js": () => import("./../src/pages/chronik.js" /* webpackChunkName: "component---src-pages-chronik-js" */),
  "component---src-pages-geschichte-js": () => import("./../src/pages/geschichte.js" /* webpackChunkName: "component---src-pages-geschichte-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-new-event-js": () => import("./../src/pages/new_event.js" /* webpackChunkName: "component---src-pages-new-event-js" */),
  "component---src-pages-previewclubs-js": () => import("./../src/pages/previewclubs.js" /* webpackChunkName: "component---src-pages-previewclubs-js" */),
  "component---src-pages-public-posts-js": () => import("./../src/pages/publicPosts.js" /* webpackChunkName: "component---src-pages-public-posts-js" */),
  "component---src-pages-veranstaltungen-js": () => import("./../src/pages/veranstaltungen.js" /* webpackChunkName: "component---src-pages-veranstaltungen-js" */),
  "component---src-templates-club-post-js": () => import("./../src/templates/clubPost.js" /* webpackChunkName: "component---src-templates-club-post-js" */),
  "component---src-templates-event-post-js": () => import("./../src/templates/eventPost.js" /* webpackChunkName: "component---src-templates-event-post-js" */)
}

